import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Grid, Stack, Accordion, AccordionDetails, SelectChangeEvent, MenuItem, TextField, Autocomplete, Box, FormHelperText } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { PlayArrow } from '@mui/icons-material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './Registration.css';
//import Logo from '../../assets/img/common/logo.png';
import RegistrationImg from '../../assets/img/registration/regstration_img.png';
import CustomTextField from '../../components/CustomTextField';
import CustomDropdownField from '../../components/CustomDropdownField';
import CustomCheckBox from '../../components/CustomCheckBox';
import { common } from '../../assets/jss/messages';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { updateUser } from '../../reduxstate/slices/userSlice';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import apiQuery from '../../apicall/apiQuery';
import { getCurrentUser, getStrapiData, callApi, getHostName } from '../../shared';
//import Banner from '../Banner';
import Navbar from '../Navbar';
// import moment from 'moment';
// import PhoneInput from 'react-phone-input-2'
// import countryList from 'react-select-country-list'
// import 'react-phone-input-2/lib/material.css'

const RegistrationPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	width: '10vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<PlayArrow className='reg_accordian_icon' />} {...props} />)(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const Registration: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { courseId, workflow, redirectURL } = useSelector((state: RootState) => state.portal);
	const [isNewUser, setIsNewUser] = React.useState(true);
	const [countryData, setCountryData] = React.useState([]);
	const [schools, setSchools] = React.useState<{ id: number; attributes: { name: string } }[]>([]);
	const params = new URLSearchParams(window.location.search);
	const redirect_url: any = params.get('redirect_url');
	const toPath: string | null = params.get('to');
	// const [expMonths, setExpMonths] = React.useState([]);
	// const [expYears, setExpYears] = React.useState([]);

	useEffect(() => {
		checkRegistration();
		GetSchoolData();
		fetchCountryData();
		// setExpMonthAndYear();
		// eslint-disable-next-line
	}, []);

	// const setExpMonthAndYear = () => {
	// 	const expMonthList: any = moment.months();
	// 	const currentYear = new Date().getFullYear();
	// 	const expYearList = Array.from({ length: 12 }, (_, index) => currentYear + index);
	// 	let months: any = [];
	// 	let years: any = [];
	// 	expMonthList.forEach((month: string, idx: number) => {
	// 		let id = String(idx + 1).padStart(2, '0');
	// 		months.push({ id: id, name: month });
	// 	});
	// 	expYearList.forEach((year: number, idx: number) => {
	// 		let id = year % 100;
	// 		years.push({ id: id, name: year });
	// 	});
	// 	setExpMonths(months);
	// 	setExpYears(years);
	// };

	const GetSchoolData = async () => {
		try {
			const data = await getStrapiData('schools?sort=name:asc&pagination[pageSize]=100');
			if (data.length > 0) {
				setSchools(data || []);
			}
		} catch (error) {}
	};
	const fetchCountryData = async (): Promise<void> => {
		try {
			const response = await fetch(`https://restcountries.com/v3/all`);
			const data = await response.json();
			console.log({ data: data });
			const options = data.map((country: any) => {
				let root_idd = country.idd.root ? country.idd.root : '';
				let suffix_idd = country.idd.suffixes && country.idd.suffixes.length > 0 ? country.idd.suffixes[0] : '';
				return {
					label: country.name.common,
					id: country.cca3,
					flag: country.flags[0],
					phCode: root_idd.concat('', suffix_idd),
				};
			});
			console.log({ options });
			setCountryData(options);
		} catch (error) {
			console.warn('Fetch Error: ', error);
		}
	};

	// console.log('countrydata',countryData)
	const checkRegistration = async () => {
		try {
			const user = await getCurrentUser();
			if (user) {
				dispatch(updateUser({ userData: user }));
				dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'info', message: common.registration_info } }));
				checkUserInfo(user);
			} else {
				navigate('/');
			}
		} catch (error) {
			navigate('/');
		}
	};

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const regex = {
		email: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
		number: new RegExp('^[0-9]+$'),
		password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
	};

	const [expanded, setExpanded] = React.useState<string | false>('panel1');

	const handleChangeExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const checkUserInfo = (user: any) => {
		apiQuery(`registration/${user.id}`, {}, 'GET', {})
			.then((result: any) => {
				setIsNewUser(false);
				setUpdateUserInfo(result);
				dispatch(updateUser({ registerData: result }));
			})
			.catch((error: any) => {
				setIsNewUser(true);
				setNewUserInfo(user);
			});
	};

	const setNewUserInfo = (user: any) => {
		setUserData({
			...userData,
			data: {
				...userData.data,
				userId: user.id,
				studentFirstName: user.firstName,
				studentLastName: user.lastName,
				studentPhCode: '+1',
				parent1PhCode: '+1',
				studentCountry: 'United States',
				parent1Country: 'United States',
			},
		});
	};

	const setUpdateUserInfo = (user: any) => {
		setUserData({
			...userData,
			data: {
				...userData.data,
				userId: user.userId,
				studentFirstName: user.studentFirstName,
				studentLastName: user.studentLastName,
				studentPhCode: user?.studentPhCode || '',
				studentCellNumber: user.studentCellNumber,
				studentAddress1: user.studentAddress1,
				studentAddress2: user.studentAddress2,
				studentState: user.studentState,
				studentCountry: user?.studentCountry || '',
				studentCity: user.studentCity,
				studentPostCode: user.studentPostCode,
				studentGrade: user.studentGrade,
				studentSchool: user.studentSchool,
				allowsms: user.allowsms,
				parent1FirstName: user.parent1FirstName,
				parent1LastName: user.parent1LastName,
				parent1PhCode: user?.parent1PhCode || '',
				parent1CellNumber: user.parent1CellNumber,
				parent1EmailAddress: user.parent1EmailAddress,
				parent1SameAsStudent: user.parent1SameAsStudent,
				parent1Address1: user.parent1Address1,
				parent1Address2: user.parent1Address2,
				parent1City: user.parent1City,
				parent1State: user.parent1State,
				parent1Country: user?.parent1Country || '',
				parent1PostCode: user.parent1PostCode,
				parent2FirstName: user.parent2FirstName,
				parent2LastName: user.parent2LastName,
				parent2PhCode: user.parent2PhCode,
				parent2CellNumber: user.parent2CellNumber,
				parent2EmailAddress: user.parent2EmailAddress,
				parent2SameAsStudent: user.parent2SameAsStudent,
				parent2Address1: user.parent2Address1,
				parent2Address2: user.parent2Address2,
				parent2City: user.parent2City,
				parent2State: user.parent2State,
				parent2Country: user.parent2Country,
				parent2PostCode: user.parent2PostCode,
				// creditCardType: user.paymentDetails && user.paymentDetails.creditCardType ? user.paymentDetails.creditCardType : '',
				// creditCardNum: user.paymentDetails && user.paymentDetails.creditCardNum ? user.paymentDetails.creditCardNum : '',
				// expirationMonth: user.paymentDetails && user.paymentDetails.expirationMonth ? user.paymentDetails.expirationMonth : '',
				// expirationYear: user.paymentDetails && user.paymentDetails.expirationYear ? user.paymentDetails.expirationYear : '',
				// nameOnAccount: user.paymentDetails && user.paymentDetails.nameOnAccount ? user.paymentDetails.nameOnAccount : '',
				// payCountry: user.paymentDetails && user.paymentDetails.country ? user.paymentDetails.country : '',
			},
		});
	};

	const [userData, setUserData] = React.useState({
		data: {
			userId: '',
			studentFirstName: '',
			studentLastName: '',
			studentPhCode: '',
			studentCellNumber: '',
			studentAddress1: '',
			studentAddress2: '',
			studentState: '',
			studentCountry: '',
			studentCity: '',
			studentPostCode: '',
			studentGrade: '',
			studentSchool: '',
			allowsms: true,
			parent1FirstName: '',
			parent1LastName: '',
			parent1PhCode: '',
			parent1CellNumber: '',
			parent1EmailAddress: '',
			parent1SameAsStudent: false,
			parent1Address1: '',
			parent1Address2: '',
			parent1City: '',
			parent1State: '',
			parent1Country: '',
			parent1PostCode: '',
			parent2FirstName: '',
			parent2LastName: '',
			parent2PhCode: '',
			parent2CellNumber: '',
			parent2EmailAddress: '',
			parent2SameAsStudent: false,
			parent2Address1: '',
			parent2Address2: '',
			parent2City: '',
			parent2State: '',
			parent2Country: '',
			parent2PostCode: '',
			// creditCardType: '',
			// creditCardNum: '',
			// expirationMonth: '',
			// expirationYear: '',
			// nameOnAccount: '',
			// payCountry: '',
		},
		error: {
			studentFirstName: false,
			studentLastName: false,
			studentPhCode: false,
			studentCellNumber: false,
			studentAddress1: false,
			studentAddress2: false,
			studentState: false,
			studentCountry: false,
			studentCity: false,
			studentPostCode: false,
			studentGrade: false,
			studentSchool: false,
			allowsms: false,
			parent1FirstName: false,
			parent1LastName: false,
			parent1PhCode: false,
			parent1CellNumber: false,
			parent1EmailAddress: false,
			parent1Address1: false,
			parent1Address2: false,
			parent1City: false,
			parent1State: false,
			parent1Country: false,
			parent1PostCode: false,
			parent2PhCode: false,
			parent2CellNumber: false,
			parent2EmailAddress: false,
			// creditCardType: false,
			// creditCardNum: false,
			// expirationMonth: false,
			// expirationYear: false,
			// nameOnAccount: false,
			// payCountry: false,
		},
		errorMessage: {
			required: common.required,
			studentCellNumber: common.required,
			parent1CellNumber: common.required,
			parent1EmailAddress: common.required,
			parent2CellNumber: common.required,
			parent2EmailAddress: common.required,
			// creditCardNum: common.required,
		},
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		let isError = false;
		let isEmail = false;
		if (event.target.required) {
			if (isEmptyOrSpaces(value)) {
				isError = true;
			}
		}

		if (name === 'parent1EmailAddress' || name === 'parent2EmailAddress') {
			if (event.target.required) {
				isEmail = regex.email.test(value);
				if (isEmptyOrSpaces(value) || !isEmail) {
					isError = true;
				}
			} else if (!isEmptyOrSpaces(value)) {
				isEmail = regex.email.test(value);
				if (!isEmail) {
					isError = true;
				}
			}
		}

		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: value,
			},
			error: {
				...userData.error,
				[name]: isError,
			},
			errorMessage: {
				...userData.errorMessage,
				[name]: isError ? (isEmptyOrSpaces(value) ? common.required : common.invalid_email) : common.required,
			},
		});
	};

	const handleCountryChange = (event: React.ChangeEvent<{}>, value: any, country: string) => {
		const name = country;
		const studentCountry = value?.label || '';
		setUserData((prevUserData) => ({
			...prevUserData,
			data: {
				...prevUserData.data,
				[name]: studentCountry,
			},
			error: {
				...prevUserData.error,
				[name]: false,
			},
			errorMessage: {
				...prevUserData.errorMessage,
				[name]: '',
			},
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		let value = event.target.value;
		setUserData((prevUserData) => ({
			...prevUserData,
			data: {
				...prevUserData.data,
				[name]: value,
			},
			error: {
				...prevUserData.error,
				[name]: false,
			},
			errorMessage: {
				...prevUserData.errorMessage,
				[name]: '',
			},
		}));
	};

	const handleNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const checked = event.target.checked;
		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: checked,
			},
		});
	};

	const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const checked = event.target.checked;
		if ((name === 'parent1SameAsStudent' || name === 'parent2SameAsStudent') && checked) {
			if (name === 'parent1SameAsStudent') {
				copyAddressForParent1();
			}

			if (name === 'parent2SameAsStudent') {
				copyAddressForParent2();
			}
		} else {
			setUserData({
				...userData,
				data: {
					...userData.data,
					[name]: checked,
				},
			});
		}
	};

	const copyAddressForParent1 = () => {
		setUserData({
			...userData,
			data: {
				...userData.data,
				parent1SameAsStudent: true,
				parent1Address1: userData.data.studentAddress1,
				parent1Address2: userData.data.studentAddress2,
				parent1City: userData.data.studentCity,
				parent1State: userData.data.studentState,
				parent1Country: userData.data.studentCountry,
				parent1PostCode: userData.data.studentPostCode,
			},
		});
	};

	const copyAddressForParent2 = () => {
		setUserData({
			...userData,
			data: {
				...userData.data,
				parent2SameAsStudent: true,
				parent2Address1: userData.data.studentAddress1,
				parent2Address2: userData.data.studentAddress2,
				parent2City: userData.data.studentCity,
				parent2State: userData.data.studentState,
				parent2Country: userData.data.studentCountry,
				parent2PostCode: userData.data.studentPostCode,
			},
		});
	};

	const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>, type?: string) => {
		const name = event.target.name;
		let only_number = event.target.value.replace(/[^0-9]/g, '');
		let final_number: any;
		if (type === 'phone') {
			// Truncate to 11 digits if necessary
			only_number = only_number.length > 11 ? only_number.substring(0, 11) : only_number;
			
			if (only_number.length < 10) {
				final_number = only_number;
			} else if (only_number.length === 10) {
				// For 10-digit numbers, format as: 3 digits - 3 digits - 4 digits
				final_number = only_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
			} else if (only_number.length === 11) {
				// For 11-digit numbers, format as: 3 digits - 3 digits - 5 digits
				final_number = only_number.replace(/(\d{3})(\d{3})(\d{5})/, '$1-$2-$3');
			}
		} else {
			final_number = only_number;
		}
		

		// if (name === 'creditCardNum') {
		// 	only_number = only_number.length > 20 ? only_number.substring(0, 20) : only_number;
		// 	final_number = only_number;
		// }

		let isError = false;
		let isPhoneNum = false;
		if (type === 'phone') {
			if (event.target.required) {
				isPhoneNum = regex.number.test(final_number);
				if (isEmptyOrSpaces(final_number) || isPhoneNum) {
					isError = true;
				}
			} else if (!isEmptyOrSpaces(final_number)) {
				isPhoneNum = regex.number.test(final_number);
				if (isPhoneNum) {
					isError = true;
				}
			}
		} else {
			if (event.target.required && isEmptyOrSpaces(final_number)) {
				isError = true;
			}
		}

		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: final_number,
			},
			error: {
				...userData.error,
				[name]: isError,
			},
			errorMessage: {
				...userData.errorMessage,
				[name]: isError ? (isPhoneNum ? common.invalid_phone : common.required) : common.required,
			},
		});
	};

	const handleManage = () => {
		let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}`;
		// let redirect_url = `http://localhost:3006`;
		window.location.replace(redirect_url);
	};

	const saveUserData = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const isVaildAll = validateAll();
		if (isVaildAll) {
			updateUserData(userData.data);
		}
	};

	const updateUserData = (user: any) => {
		const body = {
			firstName: user.studentFirstName,
			lastName: user.studentLastName,
		};
		apiQuery(`users/${user.userId}`, {}, 'PUT', body)
			.then((result: any) => {
				if (isNewUser) {
					createUserDetail(userData.data);
				} else {
					updateUserDetail(userData.data);
				}
			})
			.catch((error: any) => {});
	};

	const createUserDetail = (user: any) => {
		try {
			// let createUser = {
			// 	...user,
			// 	paymentDetails: {
			// 		// creditCardType: user.creditCardType,
			// 		creditCardNum: user.creditCardNum,
			// 		expirationMonth: user.expirationMonth,
			// 		expirationYear: user.expirationYear,
			// 		nameOnAccount: user.nameOnAccount,
			// 		country: user.payCountry,
			// 	},
			// };
			apiQuery('registration', {}, 'POST', user)
				.then((result: any) => {
					dispatch(updateUser({ registerData: result }));
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'success', message: common.save_success } }));
					sendSuccessMail();
					if (!isEmptyOrSpaces(redirect_url)) {
						window.location.replace(decodeURIComponent(redirect_url));
					} else if ((workflow === 'enroll' || workflow === 'diagnostic' || workflow === 'fulllength') && !isEmptyOrSpaces(redirectURL)) {
						window.location.replace(redirectURL);
					} else {
						if (isEmptyOrSpaces(courseId)) {
							handleManage()
						} else {
							navigate('/enroll?from=portal&code=' + courseId);
						}
					}
					if (toPath === 'dashboard') handleManage();
				})
				.catch((error: any) => {
					FailLogin(error);
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const updateUserDetail = (user: any) => {
		try {
			// let updatedUser = {
			// 	...user,
			// 	paymentDetails: {
			// 		// creditCardType: user.creditCardType,
			// 		creditCardNum: user.creditCardNum,
			// 		expirationMonth: String(user.expirationMonth).padStart(2, '0'),
			// 		expirationYear: user.expirationYear,
			// 		nameOnAccount: user.nameOnAccount,
			// 		country: user.payCountry,
			// 	},
			// };
			apiQuery(`registration/${user.userId}`, {}, 'PUT', user)
				.then((result: any) => {
					dispatch(updateUser({ registerData: result }));
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'success', message: common.save_success } }));
					if (!isEmptyOrSpaces(redirect_url)) {
						window.location.replace(decodeURIComponent(redirect_url));
					} else if (workflow === 'enroll' && !isEmptyOrSpaces(redirectURL)) {
						window.location.replace(redirectURL);
					} else {
						if (isEmptyOrSpaces(courseId)) {
							navigate('/');
						} else {
							navigate('/enroll?from=portal&code=' + courseId);
						}
					}
				})
				.catch((error: any) => {
					FailLogin(error);
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const sendSuccessMail = () => {
		let mailData: any = userData.data;
		const school = (schools || [])?.find((a: any) => a.id === userData.data.studentSchool)?.attributes?.name;
		const studentCellNumber = userData?.data?.studentPhCode + userData?.data?.studentCellNumber;
		const parent1CellNumber = userData?.data?.parent1PhCode + userData?.data?.parent1CellNumber;
		const parent2CellNumber = userData?.data?.parent2PhCode + userData?.data?.parent2CellNumber;
		mailData['studentSchoolFull'] = school;
		mailData['studentCellNumber'] = studentCellNumber;
		mailData['parent1CellNumber'] = parent1CellNumber;
		mailData['parent2CellNumber'] = parent2CellNumber;
		callApi(`auth/registrationsuccess`, 'POST', mailData);
	};

	const FailLogin = (error: any) => {
		dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.save_error } }));
		console.error('Login failed:', error);
	};

	const validateAll = () => {
		let errorStudentTab = false;
		let errorParent1Tab = false;
		let errorParent2Tab = false;
		// let errorParent3Tab = false;
		let isValidData = true;
		let studentFirstName = false;
		let studentLastName = false;
		let studentPhCode = false;
		let studentCellNumber = false;
		let studentAddress1 = false;
		let studentAddress2 = false;
		let studentState = false;
		let studentCountry = false;
		let studentCity = false;
		let studentPostCode = false;
		let studentGrade = false;
		let studentSchool = false;
		let parent1FirstName = false;
		let parent1LastName = false;
		let parent1PhCode = false;
		let parent1CellNumber = false;
		let parent1EmailAddress = false;
		let parent1Address1 = false;
		let parent1Address2 = false;
		let parent1City = false;
		let parent1State = false;
		let parent1Country = false;
		let parent1PostCode = false;
		let parent2PhCode = false;
		let parent2CellNumber = false;
		let parent2EmailAddress = false;
		// let creditCardNum = false;

		if (isEmptyOrSpaces(userData.data.studentFirstName)) {
			studentFirstName = true;
		}
		if (isEmptyOrSpaces(userData.data.studentLastName)) {
			studentLastName = true;
		}
		const snumberTest = regex.number.test(userData.data.studentCellNumber);
		if (isEmptyOrSpaces(userData.data.studentCellNumber) || snumberTest) {
			studentCellNumber = true;
		}
		if (isEmptyOrSpaces(userData.data.studentPostCode)) {
			studentPostCode = true;
		}
		if (isEmptyOrSpaces(userData.data.studentAddress1)) {
			studentAddress1 = true;
		}
		if (isEmptyOrSpaces(userData.data.studentState)) {
			studentState = true;
		}
		if (isEmptyOrSpaces(userData.data.studentCountry)) {
			studentCountry = true;
		}
		if (isEmptyOrSpaces(userData.data.studentCity)) {
			studentCity = true;
		}
		if (isEmptyOrSpaces(userData.data.studentGrade)) {
			studentGrade = true;
		}
		if (isEmptyOrSpaces(userData.data.studentSchool)) {
			studentSchool = true;
		}

		if (studentFirstName || studentLastName || studentCellNumber || studentPostCode || studentAddress1 || studentState || studentCountry || studentCountry || studentCity || studentSchool) {
			errorStudentTab = true;
		}

		if (isEmptyOrSpaces(userData.data.parent1FirstName)) {
			parent1FirstName = true;
		}
		if (isEmptyOrSpaces(userData.data.parent1LastName)) {
			parent1LastName = true;
		}
		const p1numberTest = regex.number.test(userData.data.parent1CellNumber);
		if (isEmptyOrSpaces(userData.data.parent1CellNumber) || p1numberTest) {
			parent1CellNumber = true;
		}
		const p1EmailTest = regex.email.test(userData.data.parent1EmailAddress);
		if (isEmptyOrSpaces(userData.data.parent1EmailAddress) || !p1EmailTest) {
			parent1EmailAddress = true;
		}
		if (isEmptyOrSpaces(userData.data.parent1PostCode)) {
			parent1PostCode = true;
		}
		if (isEmptyOrSpaces(userData.data.parent1Address1)) {
			parent1Address1 = true;
		}
		if (isEmptyOrSpaces(userData.data.parent1City)) {
			parent1City = true;
		}
		if (isEmptyOrSpaces(userData.data.parent1State)) {
			parent1State = true;
		}
		if (isEmptyOrSpaces(userData.data.parent1Country)) {
			parent1Country = true;
		}

		if (parent1FirstName || parent1LastName || parent1CellNumber || parent1EmailAddress || parent1PostCode || parent1Address1 || parent1State || parent1City || parent1Country) {
			errorParent1Tab = true;
		}

		const p2numberTest = regex.number.test(userData.data.parent2CellNumber);
		if (!isEmptyOrSpaces(userData.data.parent2CellNumber) && p2numberTest) {
			parent2CellNumber = true;
		}
		const p2EmailTest = regex.email.test(userData.data.parent2EmailAddress);
		if (!isEmptyOrSpaces(userData.data.parent2EmailAddress) && !p2EmailTest) {
			parent2EmailAddress = true;
		}
		// const creditCardTest = !/^\d{10,20}$/.test(userData.data.creditCardNum);
		// if (!isEmptyOrSpaces(userData.data.creditCardNum) && creditCardTest) {
		// 	creditCardNum = true;
		// }

		if (parent2CellNumber || parent2EmailAddress) {
			errorParent2Tab = true;
		}

		// if (creditCardNum) {
		// 	errorParent3Tab = true;
		// }

		if (errorStudentTab || errorParent1Tab || errorParent2Tab) {
			isValidData = false;
		}

		if (errorStudentTab) {
			setExpanded('panel1');
		} else if (errorParent1Tab) {
			setExpanded('panel2');
		} else if (errorParent2Tab) {
			setExpanded('panel3');
		}
		// else if (errorParent3Tab) {
		// 	setExpanded('panel4');
		// }

		setUserData({
			...userData,
			error: {
				...userData.error,
				studentFirstName: studentFirstName,
				studentLastName: studentLastName,
				studentPhCode: studentPhCode,
				studentCellNumber: studentCellNumber,
				studentAddress1: studentAddress1,
				studentAddress2: studentAddress2,
				studentState: studentState,
				studentCountry: studentCountry,
				studentCity: studentCity,
				studentPostCode: studentPostCode,
				studentGrade: studentGrade,
				studentSchool: studentSchool,
				parent1FirstName: parent1FirstName,
				parent1LastName: parent1LastName,
				parent1PhCode: parent1PhCode,
				parent1CellNumber: parent1CellNumber,
				parent1EmailAddress: parent1EmailAddress,
				parent1Address1: parent1Address1,
				parent1Address2: parent1Address2,
				parent1City: parent1City,
				parent1State: parent1State,
				parent1Country: parent1Country,
				parent1PostCode: parent1PostCode,
				parent2PhCode: parent2PhCode,
				parent2CellNumber: parent2CellNumber,
				parent2EmailAddress: parent2EmailAddress,
				// creditCardNum: creditCardNum,
			},
			errorMessage: {
				...userData.errorMessage,
				studentCellNumber: studentCellNumber ? (snumberTest ? common.invalid_phone : common.required) : common.required,
				parent1CellNumber: parent1CellNumber ? (p1numberTest ? common.invalid_phone : common.required) : common.required,
				parent1EmailAddress: parent1EmailAddress ? (isEmptyOrSpaces(userData.data.parent1EmailAddress) ? common.required : common.invalid_email) : common.required,
				parent2CellNumber: parent2CellNumber ? (p2numberTest ? common.invalid_phone : common.required) : common.required,
				parent2EmailAddress: parent2EmailAddress ? (isEmptyOrSpaces(userData.data.parent2EmailAddress) ? common.required : common.invalid_email) : common.required,
				// creditCardNum: common.invalid_creditcard_number,
			},
		});

		return isValidData;
	};

	const commonAutocompleteStyles = {
		'.MuiOutlinedInput-notchedOutline': {
			border: '2px solid #0146C6 !important',
			fontSize: '1.2vmax',
			borderRadius: '2vmax',
			color: 'var(--primary-font-color) !important',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#0146C6',
			borderRadius: '2vmax',
			opacity: '1',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#0146C6',
			borderRadius: '2vmax',
		},
		'.MuiInputLabel-outlined': {
			fontFamily: 'PoppinsSemiBold !important',
			fontSize: '1vmax !important',
			color: 'var(--primary-font-color) !important',
			opacity: 0.5,
		},
		'.MuiInputLabel-shrink': {
			marginLeft: '0.5vmax !important',
			opacity: '1 !important',
			fontSize: '0.85vmax !important',
		},
		'&.Mui-focused': {
			fontFamily: 'PoppinsSemiBold !important',
			fontSize: '0.85vmax !important',
			color: 'var(--primary-font-color) !important',
			marginLeft: '0.5vmax !important',
			opacity: '1',
		},
		'.MuiAutocomplete-input': {
			fontFamily: 'PoppinsSemiBold !important',
			fontSize: '1vmax !important',
			color: 'var(--primary-font-color) !important',
		},
	};

	// const handleLogoClick = () => {
	// 	navigate('/');
	// };

	return (
		<RegistrationPaper>
			{/* <div className='registration_header'>
				<div className='registration_banner'></div>
				<img className='registration_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div> */}
			<Navbar />
			<div className='registration_content'>
				<Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
					<Grid item xs={3}>
						<Grid container direction={'column'} alignItems={'center'} spacing={2}>
							<Grid item xs>
								<Typography className='registration_welcome'>Hello! {userData.data.studentFirstName}</Typography>
							</Grid>
							<Grid item xs>
								<Typography className='registration_summary'>Help Us To Understand You Better</Typography>
							</Grid>
							<Grid item xs style={{ marginTop: '20%' }}>
								<img className='registration_left_img' alt='' src={RegistrationImg} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs>
						<Stack direction={'column'} spacing={2}>
							<Accordion expanded={expanded === 'panel1'} onChange={handleChangeExpand('panel1')}>
								<AccordionSummary className='reg_accordian_summary' aria-controls='panel1d-content' id='panel1d-header'>
									<Typography className='reg_accordian_header'>
										STUDENT DETAILS<span className='reg_accordian_asterisk'>*</span>
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='STUDENT FIRST NAME'
												name='studentFirstName'
												type='text'
												required
												error={userData.error.studentFirstName}
												helperText={userData.errorMessage.required}
												value={userData.data.studentFirstName}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='STUDENT LAST NAME'
												name='studentLastName'
												type='text'
												required
												error={userData.error.studentLastName}
												helperText={userData.errorMessage.required}
												value={userData.data.studentLastName}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={2}>
											<CustomTextField
												label='DIAL CODE'
												name='studentPhCode'
												type='text'
												error={userData.error.studentPhCode}
												helperText={userData.errorMessage.required}
												value={userData.data.studentPhCode}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'dialCode');
												}}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='STUDENT CELL NUMBER'
												name='studentCellNumber'
												type='text'
												required
												error={userData.error.studentCellNumber}
												helperText={userData.errorMessage.studentCellNumber}
												value={userData.data.studentCellNumber}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'phone');
												}}
											/>
											{/* <PhoneInput
											    specialLabel='STUDENT CELL NUMBER'
												country={"us"}
												// enableSearch={true}
												value={userData.data.studentCellNumber}
												onChange={(phone) => setPhone(phone)}
												inputProps={{
													name: 'phone',
													required: true
												  }}
											      inputStyle={{
													borderRadius:"50px",
													width:"100%",
													border: "2px solid #0146C6",
													color: 'var(--primary-font-color)',
													fontFamily: 'PoppinsSemiBold'
												  }}
												  containerStyle={{
													color: 'var(--primary-font-color)',
													fontFamily: 'PoppinsSemiBold'
												  }}
											/> */}
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<Typography className='reg_section_header'>ADDRESS DETAILS:</Typography>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='STUDENT ADDRESS 1'
												name='studentAddress1'
												type='text'
												required
												error={userData.error.studentAddress1}
												helperText={userData.errorMessage.required}
												value={userData.data.studentAddress1}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='STUDENT ADDRESS 2'
												name='studentAddress2'
												type='text'
												error={userData.error.studentAddress2}
												helperText={userData.errorMessage.required}
												value={userData.data.studentAddress2}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='CITY'
												name='studentCity'
												type='text'
												required
												error={userData.error.studentCity}
												helperText={userData.errorMessage.required}
												value={userData.data.studentCity}
												onChange={handleChange}
											/>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='STATE/PROVINCE/REGION'
												name='studentState'
												type='text'
												required
												error={userData.error.studentState}
												helperText={userData.errorMessage.required}
												value={userData.data.studentState}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='POST OFFICE CODE'
												name='studentPostCode'
												type='text'
												required
												error={userData.error.studentPostCode}
												helperText={userData.errorMessage.required}
												value={userData.data.studentPostCode}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'none');
												}}
											/>
										</Grid>
										<Grid item xs>
											<Autocomplete
												id='studentCountry'
												sx={commonAutocompleteStyles}
												value={userData.data.studentCountry}
												options={countryData}
												onChange={(event, value) => {
													handleCountryChange(event, value, 'studentCountry');
												}}
												autoHighlight
												renderInput={(params) => (
													<TextField
														{...params}
														label='COUNTRY'
														required
														sx={{
															'& .MuiFormLabel-asterisk': {
																color: 'red',
															},
														}}
													/>
												)}
												renderOption={(props, option: any) => (
													<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
														<img loading='lazy' width='20' srcSet={option.flag} src={option.flag} alt='flag' />
														{option.label}
													</Box>
												)}
											/>
											<FormHelperText sx={{ color: 'red', marginLeft: 3, display: 'flex' }}>{!userData.data.studentCountry ? userData.errorMessage.required : ''}</FormHelperText>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='GRADE'
												name='studentGrade'
												type='text'
												required
												error={userData.error.studentGrade}
												helperText={userData.errorMessage.required}
												value={userData.data.studentGrade}
												onChange={handleChange}
												maxLength={3}
											/>
										</Grid>
										<Grid item xs>
											<CustomDropdownField
												label='SCHOOL'
												name='studentSchool'
												required
												error={userData.error.studentSchool}
												helperText={userData.errorMessage.required}
												value={userData.data.studentSchool}
												onChange={handleSelectChange}
											>
												<MenuItem value=''>
													<em>Not Listed</em>
												</MenuItem>
												{(schools || []).map((school: any) => (
													<MenuItem key={school.id} value={school.id}>
														{school.attributes.name}
													</MenuItem>
												))}
											</CustomDropdownField>
										</Grid>
										<Grid item xs sx={{ display: 'flex', alignItems: 'flex-start' }}>
											<Stack direction={'column'} spacing={0}>
												<CustomCheckBox label='Allow SMS Notification' name='allowsms' alignment='left' checked={userData.data.allowsms} onChange={handleNotification} />
												<Typography className='registration_policy'>By checking this box you agree to receive text messages from AlphaPrep, LLC, you can reply stop to opt-out at any time.</Typography>
												<Typography className='registration_policy'>
													<a href='/privacy-policy' target='_blank'>
														Our Privacy Policy
													</a>
												</Typography>
											</Stack>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'panel2'} onChange={handleChangeExpand('panel2')}>
								<AccordionSummary className='reg_accordian_summary' aria-controls='panel2d-content' id='panel2d-header'>
									<Typography className='reg_accordian_header'>
										PARENT 1 DETAILS<span className='reg_accordian_asterisk'>*</span>
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='PARENT FIRST NAME'
												name='parent1FirstName'
												type='text'
												required
												error={userData.error.parent1FirstName}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1FirstName}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT LAST NAME'
												name='parent1LastName'
												type='text'
												required
												error={userData.error.parent1LastName}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1LastName}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={2}>
											<CustomTextField
												label='DIAL CODE'
												name='parent1PhCode'
												type='text'
												error={userData.error.parent1PhCode}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1PhCode}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'dialCode');
												}}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT CELL NUMBER'
												name='parent1CellNumber'
												type='text'
												required
												error={userData.error.parent1CellNumber}
												helperText={userData.errorMessage.parent1CellNumber}
												value={userData.data.parent1CellNumber}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'phone');
												}}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT EMAIL ADDRESS'
												name='parent1EmailAddress'
												type='text'
												required
												error={userData.error.parent1EmailAddress}
												helperText={userData.errorMessage.parent1EmailAddress}
												value={userData.data.parent1EmailAddress}
												onChange={handleChange}
											/>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'column'} spacing={2}>
										<Grid item xs>
											<Typography className='reg_section_header'>ADDRESS DETAILS:</Typography>
										</Grid>
										<Grid item xs>
											<CustomCheckBox label='Same As Student Details' name='parent1SameAsStudent' alignment='left' checked={userData.data.parent1SameAsStudent} onChange={handleChangeCheckbox} />
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='PARENT ADDRESS 1'
												name='parent1Address1'
												type='text'
												required
												disabled={userData.data.parent1SameAsStudent}
												error={userData.error.parent1Address1}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1Address1}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT ADDRESS 2'
												name='parent1Address2'
												type='text'
												disabled={userData.data.parent1SameAsStudent}
												error={userData.error.parent1Address2}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1Address2}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='CITY'
												name='parent1City'
												type='text'
												required
												error={userData.error.parent1City}
												disabled={userData.data.parent1SameAsStudent}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1City}
												onChange={handleChange}
											/>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='STATE/PROVINCE/REGION'
												name='parent1State'
												type='text'
												required
												disabled={userData.data.parent1SameAsStudent}
												error={userData.error.parent1State}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1State}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='POST OFFICE CODE'
												name='parent1PostCode'
												type='text'
												required
												disabled={userData.data.parent1SameAsStudent}
												error={userData.error.parent1PostCode}
												helperText={userData.errorMessage.required}
												value={userData.data.parent1PostCode}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'none');
												}}
											/>
										</Grid>
										<Grid item xs>
											<Autocomplete
												id='parent1Country'
												sx={commonAutocompleteStyles}
												value={userData.data.parent1Country}
												disabled={userData.data.parent1SameAsStudent}
												options={countryData}
												onChange={(event, value) => {
													handleCountryChange(event, value, 'parent1Country');
												}}
												autoHighlight
												renderInput={(params) => (
													<TextField
														{...params}
														label='COUNTRY'
														required
														sx={{
															'& .MuiFormLabel-asterisk': {
																color: 'red',
															},
														}}
													/>
												)}
												renderOption={(props, option: any) => (
													<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
														<img loading='lazy' width='20' srcSet={option.flag} src={option.flag} alt='flag' />
														{option.label}
													</Box>
												)}
											/>
											<FormHelperText sx={{ color: 'red', marginLeft: 3, display: 'flex' }}>{!userData.data.parent1Country ? userData.errorMessage.required : ''}</FormHelperText>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'panel3'} onChange={handleChangeExpand('panel3')}>
								<AccordionSummary className='reg_accordian_summary' aria-controls='panel3d-content' id='panel3d-header'>
									<Typography className='reg_accordian_header'>PARENT 2 DETAILS</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField label='PARENT FIRST NAME' name='parent2FirstName' type='text' value={userData.data.parent2FirstName} onChange={handleChange} />
										</Grid>
										<Grid item xs>
											<CustomTextField label='PARENT LAST NAME' name='parent2LastName' type='text' value={userData.data.parent2LastName} onChange={handleChange} />
										</Grid>
										<Grid item xs={2}>
											<CustomTextField
												label='DIAL CODE'
												name='parent2PhCode'
												type='text'
												error={userData.error.parent2PhCode}
												helperText={userData.errorMessage.required}
												value={userData.data.parent2PhCode}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'dialCode');
												}}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT CELL NUMBER'
												name='parent2CellNumber'
												type='text'
												error={userData.error.parent2CellNumber}
												helperText={userData.errorMessage.parent2CellNumber}
												value={userData.data.parent2CellNumber}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'phone');
												}}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT EMAIL ADDRESS'
												name='parent2EmailAddress'
												type='text'
												error={userData.error.parent2EmailAddress}
												helperText={userData.errorMessage.parent2EmailAddress}
												value={userData.data.parent2EmailAddress}
												onChange={handleChange}
											/>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'column'} spacing={2}>
										<Grid item xs>
											<Typography className='reg_section_header'>ADDRESS DETAILS:</Typography>
										</Grid>
										<Grid item xs>
											<CustomCheckBox label='Same As Student Details' name='parent2SameAsStudent' alignment='left' checked={userData.data.parent2SameAsStudent} onChange={handleChangeCheckbox} />
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='PARENT ADDRESS 1'
												name='parent2Address1'
												type='text'
												disabled={userData.data.parent2SameAsStudent}
												value={userData.data.parent2Address1}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='PARENT ADDRESS 2'
												name='parent2Address2'
												type='text'
												disabled={userData.data.parent2SameAsStudent}
												value={userData.data.parent2Address2}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField label='CITY' name='parent2City' type='text' disabled={userData.data.parent2SameAsStudent} value={userData.data.parent2City} onChange={handleChange} />
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField
												label='STATE/PROVINCE/REGION'
												name='parent2State'
												type='text'
												disabled={userData.data.parent2SameAsStudent}
												value={userData.data.parent2State}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs>
											<CustomTextField
												label='POST OFFICE CODE'
												name='parent2PostCode'
												type='text'
												disabled={userData.data.parent2SameAsStudent}
												value={userData.data.parent2PostCode}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'none');
												}}
											/>
										</Grid>
										<Grid item xs>
											<Autocomplete
												id='parentCountry'
												disabled={userData.data.parent2SameAsStudent}
												sx={commonAutocompleteStyles}
												value={userData.data.parent2Country}
												options={countryData}
												onChange={(event, value) => {
													handleCountryChange(event, value, 'parent2Country');
												}}
												autoHighlight
												renderInput={(params) => <TextField {...params} label='COUNTRY' />}
												renderOption={(props, option: any) => (
													<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
														<img loading='lazy' width='20' srcSet={option.flag} src={option.flag} alt='flag' />
														{option.label}
													</Box>
												)}
											/>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							{/* <Accordion expanded={expanded === 'panel4'} onChange={handleChangeExpand('panel4')}>
								<AccordionSummary className='reg_accordian_summary' aria-controls='panel4d-content' id='panel4d-header'>
									<Typography className='reg_accordian_header'>PAYMENT DETAILS</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs={6}>
											<CustomTextField
												label='CREDIT CARD NO'
												name='creditCardNum'
												type='text'
												error={userData.error.creditCardNum}
												helperText={userData.errorMessage.creditCardNum}
												value={userData.data.creditCardNum}
												onChange={(event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
													event.preventDefault();
													handleChangeNumber(event, 'none');
												}}
											/>
										</Grid>
										<Grid item xs>
											<CustomDropdownField
												label='EXPIRATION MONTH'
												name='expirationMonth'
												// error={userData.error.expirationMonth}
												// helperText={userData.errorMessage.required}
												value={userData.data.expirationMonth}
												onChange={handleSelectChange}
											>
												{(expMonths || []).map((mnth: any, idx) => (
													<MenuItem key={idx} value={mnth.id}>
														{`${mnth.name} (${idx + 1})`}
													</MenuItem>
												))}
											</CustomDropdownField>
										</Grid>
										<Grid item xs>
											<CustomDropdownField
												label='EXPIRATION YEAR'
												name='expirationYear'
												// error={userData.error.expirationYear}
												// helperText={userData.errorMessage.required}
												value={userData.data.expirationYear}
												onChange={handleSelectChange}
											>
												{(expYears || []).map((year: any, idx) => (
													<MenuItem key={idx} value={year.id}>
														{year.name}
													</MenuItem>
												))}
											</CustomDropdownField>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'column'} spacing={2}>
										<Grid item xs>
											<Typography className='reg_section_header'>BILLING ADDRESS</Typography>
										</Grid>
									</Grid>
									<br />
									<Grid container direction={'row'} spacing={2}>
										<Grid item xs>
											<CustomTextField label='NAME ON ACCOUNT' name='nameOnAccount' type='text' value={userData.data.nameOnAccount} onChange={handleChange} />
										</Grid>
										<Grid item xs>
											<Autocomplete
												id='paymentCountry'
												sx={commonAutocompleteStyles}
												value={userData.data.payCountry}
												options={countryData}
												onChange={(event, value) => {
													handleCountryChange(event, value, 'payCountry');
												}}
												autoHighlight
												renderInput={(params) => (
													<TextField
														{...params}
														label='COUNTRY'
														sx={{
															'& .MuiFormLabel-asterisk': {
																color: 'red',
															},
														}}
													/>
												)}
												renderOption={(props, option: any) => (
													<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
														<img loading='lazy' width='20' srcSet={option.flag} src={option.flag} alt='flag' />
														{option.label}
													</Box>
												)}
											/>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion> */}
							<Stack justifyContent={'center'} alignItems={'center'}>
								<CustomButton variant='contained' onClick={saveUserData}>
									Save
								</CustomButton>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</div>
		</RegistrationPaper>
	);
};

export default Registration;
